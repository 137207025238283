














































import {
  defineComponent,
  reactive,
  computed,
  watch,
} from "@vue/composition-api"
import DateTime from "@/models/DateTime"
import Icon from "@/components/Icon.vue"

interface State {
  date: string
}

export default defineComponent({
  components: { Icon },
  props: {
    allowPast: {
      type: Boolean,
      required: false,
    },
    min: {
      type: Date,
      required: false,
    },
    max: {
      type: Date,
      required: false,
    },
    date: {
      type: [String, Date],
    },
    error: Boolean,
    disabled: Boolean,
  },

  setup(props) {
    const state = reactive<State>({
      date: props.date
        ? new DateTime(props.date).toDateString()
        : new DateTime().toDateString(),
    })

    const computedDateFormatted = computed(() => {
      return new DateTime(state.date).toDateStringSlash()
    })

    watch(
      () => props.date,
      newValue => {
        if (!newValue) return
        state.date = new DateTime(newValue).toDateString()
      }
    )

    return { state, computedDateFormatted }
  },
})
